import _ from 'lodash';
import { gtmPurchaseUpgrade } from '@/utils/gtmEvents';
import { metaTrackEvent, MetaTrackingEvent } from '@/utils/metaEvents';
import { xTrackEvent, XTrackingEvent } from '@/utils/xEvents';
import { snapTrackEvent, SnapTrackingEvent } from '@/utils/snapchatEvents';

export const ACTION_FETCH_PLANS = 'upgradePlanManagement/fetchPlans';
export const ACTION_GET_SUBSCRIPTION = 'upgradePlanManagement/getSubscription';
export const ACTION_REVIVE_SUBSCRIPTION = 'upgradePlanManagement/reviveSubscription';
export const ACTION_UPGRADE_PLAN = 'upgradePlanManagement/upgradePlan';
export const ACTION_VOID_INVOICE = 'upgradePlanManagement/voidInvoice';
export const ACTION_FETCH_KEYS_FOR_3DSECURE = 'upgradePlanManagement/fetchKeysFor3DSecure';
export const ACTION_GET_PLAN_UPGRADE_INFO = 'upgradePlanManagement/getPlanUpgradeInfo';

export const upgradePlanManagement = {
  namespaced: true,
  state: {
    couponApplied: false,
    isCouponValid: false,
    coupon: '',
    plans: null,
    subscription: null,
    pause: null,
    planUpgradeInfo: null,
    upgradingPlan: false,
  },
  mutations: {
    upsertCoupon(state, coupon) {
      state.coupon = coupon;
    },
    upsertPlans(state, planData) {
      _.forEach(planData.upgradePlans, (plan) => {
        if (plan.groupName === '1 Month' && plan.recurring) {
          plan.groupTitle = 'Monthly';
        }
      });
      state.plans = planData;
    },
    upsertPlanUpgradeInfo(state, planUpgradeInfo) {
      _.forEach(planUpgradeInfo.upgradePlans, (plan) => {
        if (plan.groupName === '1 Month' && plan.recurring) {
          plan.groupTitle = 'Monthly';
        }
      });
      state.planUpgradeInfo = planUpgradeInfo;
    },
    upsertQuote(state, quoteData) {
      _.forEach(quoteData.upgradePlans, (plan) => {
        if (plan.groupName === '1 Month' && plan.recurring) {
          plan.groupTitle = 'Monthly';
        }
      });
      state.quote = quoteData;
    },
    upsertSubscription(state, subscription) {
      state.subscription = subscription;
    },
    updateSubscriptionState(state, subscriptionState) {
      state.subscription.state = subscriptionState;
    },
    upsertCouponApplied(state, couponApplied) {
      state.couponApplied = couponApplied;
    },
    upsertIsCouponValid(state, isCouponValid) {
      state.isCouponValid = isCouponValid;
    },
    upsertPause(state, pause) {
      state.pause = pause;
    },
    upsertUpgradingPlan(state, upgradingPlan) {
      state.upgradingPlan = upgradingPlan;
    },
  },
  actions: {
    fetchPlans({ commit, rootState }) {
      return rootState.api.stardust.get('/plans')
        .then((response) => {
          commit('upsertPlans', response.data);
          return response.data;
        });
    },
    voidInvoice({ rootState }, invoiceId) {
      return rootState.api.stardust.post(`/invoice/${invoiceId}/void`)
        .then(response => response.data);
    },
    fetchKeysFor3DSecure({ rootState }, latestPaymentIntentId) {
      return rootState.api.stardust.get(`/payment-intent/${latestPaymentIntentId}/keys`)
        .then(response => response.data);
    },
    upgradePlan({ rootState, state }, data) {
      return rootState.api.stardust.post('/subscription/change', data)
        .then((response) => {
          const data = response.data;
          if (data.newSubscription) {
            const userId = data?.userId;
            const subscriptionId = data?.newSubscription?.id;
            const plan = data?.newSubscription?.plan;
            const cost = state?.planUpgradeInfo?.cost;

            gtmPurchaseUpgrade(userId, subscriptionId, cost, plan);
            snapTrackEvent(subscriptionId, rootState?.siteInfo?.user, plan, cost, SnapTrackingEvent.Purchase, 1, 1);

            const priceId = data?.newSubscription?.plan?.priceId;
            if (userId && priceId) {
              const sessionId = `${userId}-${priceId}`;
              metaTrackEvent(sessionId, rootState?.siteInfo?.user, plan, cost, MetaTrackingEvent.Purchase);
              xTrackEvent(sessionId, rootState?.siteInfo?.user, plan, cost, XTrackingEvent.Purchase);
            }
          }

          return response.data;
        });
    },
    checkCouponForUpgrade({ commit, rootState }, { coupon, oldPriceId, newPriceId }) {
      return rootState.api.stardust.get(`subscription/upgrade-info?coupon=${coupon}&oldPriceId=${oldPriceId}&newPriceId=${newPriceId}`)
        .then((response) => {
          commit('upsertPlanUpgradeInfo', response.data);
          commit('upsertCouponApplied', true);
          commit('upsertIsCouponValid', true);
          commit('upsertCoupon', coupon);
          return response.data;
        });
    },
    getPlanUpgradeInfo({ commit, rootState }, { oldPriceId, newPriceId }) {
      const quoteReturn = {
      };
      const response = rootState.api.stardust.get(`/subscription/upgrade-info?oldPriceId=${oldPriceId}&newPriceId=${newPriceId}`)
        .then((response) => {
          commit('upsertPlanUpgradeInfo', response.data);
          commit('upsertCouponApplied', false);
          commit('upsertIsCouponValid', false);
          return response.data;
        });
      return quoteReturn;
    },
    getFeedbackInfo({ rootState }) {
      return rootState.api.stardust.get('/feedback/info')
        .then(response => response.data);
    },
    cancelPlan({ rootState }, reason) {
      return rootState.api.stardust.delete('/subscription', {
        data: {
          cancellationReason: reason,
        },
      })
        .then(response => response.data);
    },
    getSubscription({ commit, rootState }) {
      return rootState.api.stardust.get('/subscription')
        .then((response) => {
          commit('upsertSubscription', response.data);

          return response.data;
        });
    },
    reviveSubscription({ commit, rootState }) {
      return rootState.api.stardust.post('/subscription/revive')
        .then((response) => {
          commit('upsertSubscription', response.data);

          return response.data;
        });
    },
    getPause({ commit, rootState }) {
      return rootState.api.stardust.get('/pause')
        .then((response) => {
          commit('upsertPause', response.data);
          return response.data;
        });
    },
    postPause({ commit, rootState }, data) {
      return rootState.api.stardust.post('/pause', data)
        .then((response) => {
          commit('upsertPause', response.data);
          return response.data;
        });
    },
    patchPause({ commit, rootState }, data) {
      return rootState.api.stardust.patch('/pause', data)
        .then((response) => {
          commit('upsertPause', response.data);
          return response.data;
        });
    },
    patchPauseEndDate({ commit, rootState }, data) {
      return rootState.api.stardust.patch('/pause/end-date', data)
        .then((response) => {
          commit('upsertPause', response.data);
          return response.data;
        });
    },
    deletePause({ commit, rootState }) {
      return rootState.api.stardust.delete('/unpause')
        .then((response) => {
          commit('upsertPause', response.data);
          return response.data;
        });
    },
  },
};
